import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
const Reportes = () => {
    const [reporte, setReporte] = useState([]);
    const [reporteArticulos, setReporteArticulos] = useState([]);
    const [reporteMeseros, setReporteMeseros] = useState([]);
    const [sumaTotalReporte, setSumaTotalReporte] = useState(0);
    useEffect(() => {
        const toDay = new Date();
        const dia = toDay.getDate() < 10 ? `0${toDay.getDate()}` : `${toDay.getDate()}`;
        const mes = toDay.getMonth() + 1 < 10 ? `0${toDay.getMonth() + 1}` : `${toDay.getMonth() + 1}`;
        const fechaActual = `${toDay.getFullYear()}-${mes}-${dia}`;
        cargarReportes(fechaActual);
    }, []);

    const cargarReportes = (fecha) => {

        const data = {
            fecha: fecha
        };
        axios.post('https://mariscosdelvalle.com.mx/apis/cargarReporte.php', data, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        })
            .then((response) => {
                const arrayArticulos = [];
                const arrayMeseros = [];
                const reporteCompleto = [];
                //arreglo de articulos
                for (let i = 0; i < response.data.length; i++) {
                    let producto = arrayArticulos.some(produc => produc.producto === response.data[i].producto);
                    if (producto === false) {
                        arrayArticulos.push({
                            categoria: response.data[i].categoria,
                            cantidad: parseInt(response.data[i].cantidad),
                            precio: parseInt(response.data[i].precio),
                            producto: response.data[i].producto
                        });
                    } else if (producto === true) {
                        let index = arrayArticulos.findIndex(indexProd => indexProd.producto === response.data[i].producto);
                        if (index !== '-1') {
                            arrayArticulos[index].cantidad = arrayArticulos[index].cantidad + parseInt(response.data[i].cantidad);
                        }
                    }
                }
                //arreglo de meseros
                for (let a = 0; a < response.data.length; a++) {
                    let mesero = arrayMeseros.some(meseroArray => meseroArray.nombre === response.data[a].mesero);
                    if (mesero === false) {
                        arrayMeseros.push({
                            nombre: response.data[a].mesero,
                            total: response.data[a].cantidad * response.data[a].precio
                        });
                    } else if (mesero === true) {
                        let index = arrayMeseros.findIndex(indexProd => indexProd.nombre === response.data[a].mesero);
                        if (index !== '-1') {
                            arrayMeseros[index].total = arrayMeseros[index].total + (parseInt(response.data[a].precio) * parseInt(response.data[a].cantidad));
                        }
                    }
                }
                //REPORTE COMPLETO
                for (let b = 0; b < response.data.length; b++) {
                    let servicio = reporteCompleto.some(reporteFinal => reporteFinal.noOrden === response.data[b].noOrden);
                    if (servicio === false) {
                        reporteCompleto.push({
                            nombreMesa: response.data[b].mesa,
                            noOrden: response.data[b].noOrden,
                            hora: response.data[b].hora,
                            mesero: response.data[b].mesero,
                            total: response.data[b].precio * response.data[b].cantidad,
                            pagado: response.data[b].estado,
                            cancelado: response.data[b].estado === 'Pendiente' ? 0 : 1,
                        });
                    } else if (servicio === true) {
                        let index = reporteCompleto.findIndex(indexProd => indexProd.noOrden === response.data[b].noOrden);
                        if (index !== '-1') {
                            reporteCompleto[index].total = reporteCompleto[index].total + (parseInt(response.data[b].precio) * parseInt(response.data[b].cantidad));
                        }
                    }
                }
                let cuentaTotal = 0;
                for (let cuenta = 0; cuenta < reporteCompleto.length; cuenta++) {
                    cuentaTotal = cuentaTotal + reporteCompleto[cuenta].total;
                }
                setSumaTotalReporte(cuentaTotal);
                setReporte(reporteCompleto);
                setReporteMeseros(arrayMeseros);
                setReporteArticulos(arrayArticulos);
            })
            .catch((error) => console.error(error));
    }

    return (
        <>
            <TableContainer component={Paper} size="small" sx={{ overflowX: 'hidden' }}>
                <Table sx={{ width: '100%' }} aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{ background: '#93E1D8' }}>
                            <TableCell
                                align="right"
                                sx={{
                                    display: { xs: 'none', sm: 'table-cell' }  // Oculta en pantallas pequeñas
                                }}
                            >PEDIDOS</TableCell>
                            <TableCell
                            ><b>Servicios</b></TableCell>
                            <TableCell
                                align="right"
                                sx={{
                                    display: { xs: 'none', sm: 'table-cell' }  // Oculta en pantallas pequeñas
                                }}
                            ><b>Cancelado</b></TableCell>
                            <TableCell
                                align="right"
                                sx={{
                                    display: { xs: 'none', sm: 'table-cell' }  // Oculta en pantallas pequeñas
                                }}
                            ><b>Pagado</b></TableCell>
                            <TableCell
                                align="right"
                                sx={{
                                    display: { xs: 'none', sm: 'table-cell' }  // Oculta en pantallas pequeñas
                                }}
                            ><b>Mesero</b></TableCell>
                            <TableCell
                                align="right"
                            ><b>Total (${sumaTotalReporte})</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reporte ? reporte.map((array, index) => (
                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row"
                                    align="right"
                                    sx={{
                                        display: { xs: 'none', sm: 'table-cell' }  // Oculta en pantallas pequeñas
                                    }}>{array.hora}</TableCell>
                                <TableCell
                                >{array.nombreMesa}</TableCell>
                                <TableCell
                                    align="right"
                                    sx={{
                                        display: { xs: 'none', sm: 'table-cell' }  // Oculta en pantallas pequeñas
                                    }}
                                >{array.cancelado}</TableCell>
                                <TableCell
                                    align="right"
                                    sx={{
                                        display: { xs: 'none', sm: 'table-cell' }  // Oculta en pantallas pequeñas
                                    }}
                                >
                                    {array.pagado === 'Pendiente' ? <ClearIcon /> : <CheckIcon />}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    sx={{
                                        display: { xs: 'none', sm: 'table-cell' }  // Oculta en pantallas pequeñas
                                    }}
                                >{array.mesero}</TableCell>
                                <TableCell>${array.total}</TableCell>
                            </TableRow>
                        )) : null}
                    </TableBody>
                </Table>
            </TableContainer >

            {/* USUARIOS REPORTE */}
            < TableContainer component={Paper} sx={{ marginTop: 2, overflowX: 'hidden' }
            } size="small" >
                <Table sx={{ width: '100%' }} aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{ background: '#BC4749' }}>
                            <TableCell><b>USUARIOS</b></TableCell>
                            <TableCell><b>TOTAL</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reporteMeseros ? reporteMeseros.map((arrayMeseros, indexMeseros) => (
                            <TableRow key={indexMeseros} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">{arrayMeseros.nombre}</TableCell>
                                <TableCell component="th" scope="row" align="right">${arrayMeseros.total}</TableCell>
                            </TableRow>
                        )) : null}
                    </TableBody>
                </Table>
            </TableContainer >

            {/* Reporte artículos */}
            < TableContainer component={Paper} sx={{ marginTop: 2, overflowX: 'hidden' }} size="small" >
                <Table sx={{ width: '100%' }} aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{ background: '#CBB3BF' }}>
                            <TableCell><b>({reporteArticulos.length === 0 ? null : reporteArticulos.length + 1}) Artículos</b></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reporteArticulos ? reporteArticulos.map((arrayArticulos, indexArticulos) => (
                            <TableRow key={indexArticulos} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                    {arrayArticulos.cantidad} - {arrayArticulos.producto}
                                </TableCell>
                                <TableCell component="th" scope="row" align="right">
                                    ${arrayArticulos.cantidad * arrayArticulos.precio}
                                </TableCell>
                            </TableRow>
                        )) : null}
                    </TableBody>
                </Table>
            </TableContainer >
        </>
    );
};

export default Reportes;