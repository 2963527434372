import React, { useState, useEffect, useRef } from 'react';
import { Typography, IconButton, } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Card, CardContent, List, ListItem, ListItemText } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PaidIcon from '@mui/icons-material/Paid';

const CardMesa = ({ value, noOrden, funcion, pagar }) => {
    const [optMenu, setOptMenu] = useState(false);
    const menuRef = useRef(null);
    const rol = sessionStorage.getItem('rol');
    const toggleMenu = () => {
        setOptMenu((prev) => !prev);
    };



    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setOptMenu(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return (
        <Grid
            item
            xs={12} sm={6} md={6} lg={6} // 4 columnas en pantallas grandes, 2 en medianas, 1 en pequeñas 
            key={noOrden}
            sx={{ width: '100%' }}

        >
            <Card style={{ position: 'relative', background: '#F3F0F7' }}  >
                {/* Botón de tres puntos */}
                <IconButton
                    style={{ position: 'absolute', top: 8, right: 8 }}
                    onClick={toggleMenu}
                >
                    <MoreVertIcon />
                </IconButton>


                <div style={{ position: 'relative' }} on>
                    {optMenu && (
                        <div
                            ref={menuRef}
                            style={{
                                position: 'absolute',
                                top: '40px',
                                right: '0',
                                border: '1px solid #ccc',
                                background: '#fff',
                                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                                zIndex: 1,

                            }}>
                            <div onClick={() => funcion('Editar', value)} style={{ padding: '10px', cursor: 'pointer' }}>
                                Editar
                            </div>
                            {rol === 'Admin' ?
                                <div onClick={() => { funcion('Eliminar', value[0].id_mesa); setOptMenu(false); }} style={{ padding: '10px', cursor: 'pointer' }}>
                                    Eliminar
                                </div>
                                : null}

                        </div>
                    )}
                </div>

                {/* <CardContent sx={{ margin: 1 }}>
                    <Typography variant="h6" gutterBottom>
                        Mesa: {value[0].mesa}
                    </Typography>
                    <List >
                        {Object.entries(value).map(([index, array]) => (
                            <ListItem key={index}>
                                <ListItemText
                                    primary={`${array.cantidad} - ${array.producto}`}
                                />
                            </ListItem>
                        ))}
                    </List>
                </CardContent> */}
                <CardContent sx={{ margin: 1 }}>
                    <Typography variant="h6" gutterBottom>
                        Mesa: {value[0].mesa}
                    </Typography>

                    {/* Grid para hacer columnas cuando haya más de 7 productos */}
                    <Grid container sx={{ flexWrap: 'wrap' }}>
                        {Object.entries(value).map(([index, array], i) => (
                            <Grid
                                item
                                xs={12} // 12 para que cada producto ocupe toda la fila en xs
                                sm={6} // 6 para hacer dos columnas en sm (pantallas medianas)
                                md={6} // 4 para tres columnas en pantallas más grandes
                                lg={6}
                                key={index}
                            >
                                <List sx={{ padding: 0 }}>
                                    <ListItem sx={{ padding: '4px 0' }}>
                                        <ListItemText primary={`${array.cantidad} - ${array.producto}`} />
                                    </ListItem>
                                </List>
                            </Grid>
                        ))}
                    </Grid>
                </CardContent>
                <div style={{ display: 'flex', justifyContent: 'center', padding: '2px' }}>
                    <PaidIcon color='success' sx={{ fontSize: 40 }} onClick={() => { pagar('Pagar', value); setOptMenu(false); }} />
                </div>
            </Card >
        </Grid >
    )
}

export default CardMesa;