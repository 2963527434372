import React, { useState } from 'react';
import { AppBar, Tabs, Tab, Toolbar, Typography, Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Pedidos from './componentes/Pedidos.jsx';
import Menu from './componentes/Menu.jsx';
import Personal from './componentes/Personal.jsx';
import Reporte from './componentes/Reportes.jsx';

// Crear un tema personalizado con un gradiente azul
const theme = createTheme({
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    background: 'linear-gradient(to bottom, #1fa5ff, #1fa5ff, #1fa5ff, #1fa5ff, #1fa5ff);',
                },
            },
        },
    },
});

const PuntoDeVenta = () => {
    const [tabValue, setTabValue] = useState(0);
    const nombre = sessionStorage.getItem('Nombre');
    const rol = sessionStorage.getItem('rol');
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <ThemeProvider theme={theme}>
            <Box
                className="min-h-screen bg-cover bg-center" // Clases de Tailwind para fondo y altura de pantalla completa
                sx={{
                    flexGrow: 1,
                    minHeight: '100vh',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                {/* Barra superior */}
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            Usuario: {nombre}
                        </Typography>
                    </Toolbar>
                </AppBar>

                {/* Tabs responsivos */}
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="tabs responsivos"
                    sx={{
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        '& .MuiTabs-flexContainer': {
                            justifyContent: 'center',
                        },
                    }}
                    className="border-b border-gray-300" // Borde inferior con Tailwind
                >
                    <Tab label="PEDIDOS" className="text-sm sm:text-base" />  {/* Responsivo para texto pequeño */}
                    {rol === 'Admin' ? <Tab label="MENÚ" className="text-sm sm:text-base" /> : null}
                    {rol === 'Admin' ? <Tab label="PERSONAL" className="text-sm sm:text-base" /> : null}
                    {rol === 'Admin' ? <Tab label="REPORTES" className="text-sm sm:text-base" /> : null}
                </Tabs>

                {/* Contenido de los tabs con scroll */}
                <Box
                    className="max-h-[80vh] overflow-y-auto p-2 sm:p-4" // Clases para altura, scroll y padding responsivo
                    sx={{
                        maxHeight: '80vh',
                        overflowY: 'auto',
                        padding: 2,
                    }}
                >
                    {tabValue === 0 && <Pedidos />}
                    {tabValue === 1 && <Menu />}
                    {tabValue === 2 && <Typography><Personal /></Typography>}
                    {tabValue === 3 && <Typography><Reporte /></Typography>}
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default PuntoDeVenta;
